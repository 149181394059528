import React, { useEffect, useRef } from 'react';
import './Home.css';
import MyWork from './MyWork.js';
import About from './About.js';

function Home() {

    return (
        <React.Fragment>
            <div className="home-main" id="home">
                <div className="home-name">Anonymous</div>
                <div className="home-subname">Computer Science Student | Software Engineer | UI/UX Designer</div>
                <div className="home-intro">
                    Hello! My name is Anonymous, I am a Computer Science Student at Brown University. I like
                    to think of myself as both a a software engineer, but I recently started to immerse myself in
                    the world of UI/UX. Please enjoy my portfolio as you go through this journey with me!
                </div>
            </div>
            <div className="home-section-title" id="myWork">My Work</div>
            <MyWork />
            <div className="home-section-title" id="aboutMe">About Me</div>
            <About />
        </React.Fragment>
    );
}

export default Home;