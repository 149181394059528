import React from 'react';
import './MyWork.css';
import IterativeDesign from './images/iterative-design.png';
import ResponsiveRedesign from './images/responsive-redesign.png';
import Personas from './images/personas.png';
import Development from './images/development.png';

function MyWork() {
    return (
        <div className="mywork-main">
            <a href="/personas" style={{ textDecoration: "none" }}>
                <div className="mywork-content">
                    <img className="mywork-content-image" src={Personas}></img>
                    <div className="mywork-content-text">
                        <div className="mywork-content-title">1) Personas & Storyboarding</div>
                        <div className="mywork-content-subtitle">
                            This is where my journey starts. I stepped into a user’s shoes. Observed real users interacting with an interface,
                            interviewed these individuals about their experiences, created personas based on these users,
                            and illustrated a storyboard for one of my personas. A classic UX task.
                        </div>
                    </div>
                </div>
            </a>
            <a href="/iterative" style={{ textDecoration: "none" }}>
                <div className="mywork-content">
                    <img className="mywork-content-image" src={IterativeDesign}></img>
                    <div className="mywork-content-text">
                        <div className="mywork-content-title">2) Iterative Design</div>
                        <div className="mywork-content-subtitle">
                            I explored the process of iteratively designing an interactive interface for an emerging startup.
                            Went through the full process of mocking up a solution to the startup's concept.
                        </div>
                    </div>
                </div>
            </a>
            <a href="/responsive" style={{ textDecoration: "none" }}>
                <div className="mywork-content">
                    <img className="mywork-content-image" src={ResponsiveRedesign}></img>
                    <div className="mywork-content-text">
                        <div className="mywork-content-title">3) Responsive Redesign</div>
                        <div className="mywork-content-subtitle">
                            Practiced the workflow of redesigning a simple website. Utilized the skills necessary
                            to analyze and identify flaws in an existing interface, create low-fidelity and high-fidelity prototypes
                            for various screen sizes, and build a responsive website based on those prototypes.
                        </div>
                    </div>
                </div>
            </a>
            <a href="/development" style={{ textDecoration: "none" }}>
                <div className="mywork-content">
                    <img className="mywork-content-image" src={Development}></img>
                    <div className="mywork-content-text">
                        <div className="mywork-content-title">4) Development</div>
                        <div className="mywork-content-subtitle">
                            Finally, developed an interactive interface, used interface components,
                            and tied the components to an internal data state. I combined everything I 
                            had learned into one project. Built with React JS.
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default MyWork;