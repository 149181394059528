import React from 'react';
import './Main.css';
import Home from './Home.js';
import Personas from './projects/Personas.js';
import Iterative from './projects/Iterative.js';
import Responsive from './projects/Responsive.js';
import Development from './projects/Development.js';
import WorldCup from './projects/WorldCup/WorldCup.js';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

function Main() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="/home" element={<Home />}></Route>
                <Route path="/personas" element={<Personas />}></Route>
                <Route path="/iterative" element={<Iterative />}></Route>
                <Route path="/responsive" element={<Responsive />}></Route>
                <Route path="/development" element={<Development />}></Route>
                <Route path="/world-cup" element={<WorldCup />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default Main;