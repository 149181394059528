import React, { useEffect, useState } from 'react';
import './NavBar.css';
import MQLogo from './images/anonymous-logo.png';

function NavBar() {
    const [link, setLink] = useState("")

    useEffect(() => {
        console.log(window.location.href)
        setLink(window.location.href)
    })

    function isAtHome() {
        if (link.includes("#home") || link.includes("#myWork") || link.includes("#aboutMe") || link.includes("/home")) {
            return true;
        }
        return false;
    }

    return (
        <div className="NavBar" id="home">
            <a href="/home" style={{ textDecoration: "none", marginTop: "10px"}}>
                <img className="logo" src={MQLogo}></img>
            </a>
            <div className="NavBarLinks">
                <a href="/home" style={{ textDecoration: "none" }}>
                    <p className="NavBarLink">Home</p>
                </a>
                {isAtHome() &&
                    <a href="/home#myWork" style={{ textDecoration: "none" }}>
                        <p className="NavBarLink">My Work</p>
                    </a>
                }
                {isAtHome() &&
                    <a href="/home#aboutMe" style={{ textDecoration: "none" }}>
                        <p className="NavBarLink">About Me</p>
                    </a>
                }
            </div>
        </div>
    );
}

export default NavBar;