import React from 'react';
import './Development.css';
import JSON from './images/json.png';

function Development() {

    return (
        <div className="main">
            <div className="section">
                <h1 className="title">Development</h1>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                Why?
                            </td>
                            <td>
                                Practice developing an interactive interface, using interface components, and tying
                                the components to an internal data state.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                How?
                            </td>
                            <td>
                                Choosing a concept and building it with React JS by creating a list-based interface.
                                Basically, an interface for users to select items out of a list, which then aggregates them.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                What?
                            </td>
                            <td>
                                The project exemplifies an interface handled with React state variables that filters out
                                the group stage matches of the 2022 FIFA World Cup in Qatar.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                My take on it:
                            </td>
                            <td>
                                Now I have a much better understanding of React JS, how to use state variables, and how to build responsive websites. This was 
                                the culmination of my journey in UI/UX where I put together different skills to build a successful interface.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 1: Choosing a concept</p>
                <p className="text">
                    This website was built in December of 2022, and what better excuse than to use the FIFA World Cup! I managed
                    to get a JSON file from the world wide web which contained information on the group stage matches. I also managed
                    to download all of the different flags for the countries participating in this tournament.
                    <br></br><br></br>
                    This is what a small piece of the JSON object looks like:
                    <br></br><br></br>
                    <img className="json-image" src={JSON} alt="json"></img>
                </p>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 2: Architecture</p>
                <div className="text">
                    I then had to pick a theme for my list and aggregator interface. I thought about the elements that I was making into components, props, or states.
                    The features I included where:
                    <ul style={{ marginTop: "10px" }}>
                        <li>2 filter categories</li>
                        <li>2 sorting features</li>
                        <li>A Favorites (aggregator) section</li>
                        <li>At least 12 item cards</li>
                    </ul>
                    The website is able to handle any combination of filters, and all filters and sorting methods work
                    together without breaking the page. There is also a reset button which allows the user to revert back to the
                    original state without refreshing the page. Lastly, there is a rating calculator which takes the average of each
                    match selected in the Favorites section.
                </div>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 3: Putting it all together</p>
                <p className="text">
                    You can access the website <a style={{ color: "inherit" }} href="/world-cup">here</a>!
                </p>
            </div>
        </div>
    );
}

export default Development;