import React from 'react';
import './About.css';
import Picture from './images/anonymous-picture.png';
import { FaLinkedin, FaGithub, FaPen } from 'react-icons/fa';

function About() {
    return (
        <div className="about-main">
            <img className="about-picture" src={Picture}></img>
            <div className="about-info">
                My name is Anonymous, I am a student at Brown University studying
                Computer Science.
                <br></br><br></br>
                When I'm not designing or programming, I'm making or listening to music, cooking,
                working out, or traveling the world. I would love to hear from you, please
                connect with me or email me anytime!
            </div>
            <div className="about-icons">
                <a href="https://cs.brown.edu/courses/csci1300/" style={{ color: "inherit" }} target="_blank" rel="noreferrer">
                    <FaLinkedin size={30} />
                </a>
                <a href="https://cs.brown.edu/courses/csci1300/" style={{ color: "inherit" }} target="_blank" rel="noreferrer">
                    <FaGithub size={30} />
                </a>
                <a href="https://cs.brown.edu/courses/csci1300/" style={{ color: "inherit" }} target="_blank" rel="noreferrer">
                    <FaPen size={25} style={{ marginTop: 3 }} />
                </a>
            </div>
        </div>
    );
}

export default About;