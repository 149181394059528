import React from 'react';
import './Responsive.css';
import WebsiteSS from './images/website-screenshot.png';
import LowFiPhone from './images/lowfi-phone.png';
import LowFiTablet from './images/lowfi-tablet.png';
import LowFiDesktop from './images/lowfi-desktop.png';
import SDG from './images/style-design-guide.png'
import HiFiPhone from './images/hifi-phone.png';
import HiFiTablet from './images/hifi-tablet.png';
import HiFiDesktop from './images/hifi-desktop.png';

function Responsive() {

    return (
        <div className="main">
            <div className="section">
                <h1 className="title">Responsive Redesign</h1>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                Why?
                            </td>
                            <td>
                                To think about how important it is for a website to be responsive so that it can fit different screen sizes
                                and be accessible to all users.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                How?
                            </td>
                            <td>
                                I picked one page from a website that I believe contains
                                important usability and accessibility issues.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                What?
                            </td>
                            <td>
                                With new and improved usability and accessibility standards,
                                I then redesigned and built a redesigned version of this webpage.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "black", fontWeight: "bold" }}>
                                My take on it:
                            </td>
                            <td>
                                I got experience in designing responsive websites and now understand the importance of accessibility.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 1: Identifying Usability Problems</p>
                <p className="text">
                    The webpage chosen was the Home page for Townies Feel Good Food, a local pizza restaurant in Providence, RI. I chose
                    this website because 1) it is a restaurant that I like here in Providence (where I go to school), and 2)
                    from using this website as a customer I know there is significant room for improvement both from a
                    design point of view and a usability and accessibility point of view. Below
                    you can find a screenshot and clicking on the image will also redirect you to the webpage itself.
                </p>
                <img className="screenshot-image" onClick={() => { window.open("https://towniesri.com/index.html") }} src={WebsiteSS} alt="website-screenshot"></img>
                <p className="section-title">Finding problems</p>
                <p className="text">
                    Using core concepts of design and criteria learned in class, I have analyzed and noted down the webpage's
                    problems in the following table:
                </p>
                <table>
                    <tbody>
                        <tr>
                            <th>Usability</th>
                            <th>Learnability</th>
                            <th>Memorability</th>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>For a Home page, I believe this webpage is a little bit too long. Every element is very spaced out and
                                        could be collapsed into a smaller space to improve usability.
                                    </li>
                                    <li>Some of the words in the bottom navigation bar lack contrast, making it hard for some users to read.</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>The webpage is very spaced out and lacks structure, making it easier for the user to quickly pick up and understand
                                        how to use it. </li>
                                    <li>The webpage lacks a good hierarchy, meaning the important information such as opening hours or location
                                        lack the relevancy that they require. Furthermore, long sentences can definitely be shortened into more concise
                                        pieces of information.
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>As mentioned in other sections, the webpage lacks a clear structure and basically has everything in a singular
                                        centered column, making it hard for users to remember where things are.
                                    </li>
                                    <li>At first sight, the Home page (in a regular laptop screen) displays a huge prompt, but not the important things such as
                                        working hours, the menu, etc. This takes a toll on memorability and ease of use for the user.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className="section-title">Accessibility</p>
                <div className="text">
                    Using <a href="https://wave.webaim.org/" target="_blank" rel="noreferrer" id="link">WebAIM WAVE</a> I detected possible accessibility problems.
                    <ul style={{ marginBottom: 0, marginTop: "10px" }}>
                        <li>There were 7 developer errors involving things such as missing alternative text and empty links. These can become
                            an issue for browsers with weak internet connections that do not load every element of the webpage at once.
                        </li>
                        <li>
                            As mentioned in the section before, the grey text in the bottom navigation bar lacks contrast, which is definitely an issue
                            for accessibility.
                        </li>
                        <li>
                            Another interesting finding was that the Home navigation bar link is redundant, which makes sense because the logo also redirects to the Home page.
                            Although I do not agree that this is redundant, it could be something worth thinking about when developing a website.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 2: Visual Redesign</p>
                <p className="text">
                    Taking into consideration everything mentioned above, I have created low-fidelity wireframes, a visual design style guide, and high-fidelity prototypes.
                </p>
                <p className="section-title">Low-Fidelity Wireframing</p>
                <p className="text">
                    I designed low-fidelity wireframes which directly attempt to solve the problems above while maintaining a responsive design.
                </p>
                <p className="subtitle" style={{ marginTop: "0px" }}>Desktop</p>
                <img className="screenshot-image" style={{ cursor: "auto" }} src={LowFiDesktop} alt="lowfi-desktop"></img>
                <div className="lowfi-devices">
                    <div className="lowfi-devices-left">
                        <p className="subtitle">Phone</p>
                        <img className="lowfi-image-phone" src={LowFiPhone} alt="lowfi-phone"></img>
                    </div>
                    <div className="lowfi-devices-right">
                        <p className="subtitle" style={{ textAlign: "right" }}>Tablet</p>
                        <img className="lowfi-image-tablet" src={LowFiTablet} alt="lowfi-tablet"></img>
                    </div>
                </div>
                <p className="section-title">Visual Design Style Guide</p>
                <p className="text">
                    I also created a Visual Design Style Guide using Figma to clearly document my design work and have consistent UI throughout the webpage.
                </p>
                <img className="screenshot-image" style={{ cursor: "auto" }} src={SDG} alt="style-design-guide"></img>
                <p className="section-title">High-Fidelity Prototyping</p>
                <p className="text">
                    Finally, using Figma, I created high-fidelity prototypes of my screen. This webpage is designed to be responsive and
                    adjust to different screen sizes.
                </p>
                <p className="subtitle" style={{ marginTop: "0px" }}>Desktop</p>
                <img className="screenshot-image" style={{ cursor: "auto" }} src={HiFiDesktop} alt="lowfi-desktop"></img>
                <div className="lowfi-devices">
                    <div className="lowfi-devices-left">
                        <p className="subtitle">Phone</p>
                        <img className="hifi-image-phone" src={HiFiPhone} alt="lowfi-phone"></img>
                    </div>
                    <div className="lowfi-devices-right">
                        <p className="subtitle" style={{ textAlign: "right" }}>Tablet</p>
                        <img className="hifi-image-tablet" src={HiFiTablet} alt="lowfi-tablet"></img>
                    </div>
                </div>
            </div>

            <div className="section" style={{ marginTop: "-10px" }}>
                <p className="sub-title">Part 3: Responsive Redesign</p>
                <p className="text">
                    Using the final high-fidelity prototypes, I have created the redesigned webpage! Since it is just the
                    Home page (and not the entire website), buttons were replaced by dummy elements and the webpage
                    is not interactive.
                    <br></br><br></br>
                    You can view the responsive redesigned webpage <a id="link" href="https://cs1300-townies-feel-good-food-redesign.pages.dev/">here</a>!
                </p>
            </div>
        </div>
    );
}

export default Responsive;